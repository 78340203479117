<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-edutrain-i06">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form @submit.prevent>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">개인금융 – 교과목</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="kb-table kb-table-striped">
              <table>
                <colgroup>
                  <col style="width:270px;"/>
                  <col style="width:150px;"/>
                  <col style="width:120px;"/>
                  <col style="width:120px;"/>
                  <col style="width:120px;"/>
                  <col style="width:auto;"/>
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">자격증명</span></th>
                  <th><span class="th-title">주관처</span></th>
                  <th><span class="th-title">등재일</span></th>
                  <th><span class="th-title">취득날짜</span></th>
                  <th><span class="th-title">지원금액</span></th>
                  <th><span class="th-title">비고</span></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><strong class="td-text">펀드투자 권유자문인력(증권)</strong></td>
                  <td><span class="td-text">한국금융투자협회</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">증권펀드투자상담사(구, 간투 시험)(WR0X)/ 2007.11.16취득</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">펀드투자 권유자문인력(파생상품)</strong></td>
                  <td><span class="td-text">한국금융투자협회</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">파생상품펀드투자상담사(WR20)/ 2009.03.20 취득</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">펀드투자 권유자문인력(부동산)</strong></td>
                  <td><span class="td-text">한국금융투자협회</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">450,000</span></td>
                  <td><span class="td-text">-</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">파생상품투자 권유자문인력</strong></td>
                  <td><span class="td-text">금융투자협회</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">100,000</span></td>
                  <td><span class="td-text">-</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">보험대리점(생명보험)</strong></td>
                  <td><span class="td-text">보험연수원</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">보험대리점(제3보험)</strong></td>
                  <td><span class="td-text">보험연수원</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">파생상품펀드투자상담사(WR20)/ 2009.03.20 취득</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">보험대리점(손해보험)</strong></td>
                  <td><span class="td-text">보험연수원</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                </tr>
                <tr>
                  <td><strong class="td-text">보험대리점(변액보험)</strong></td>
                  <td><span class="td-text">생명보험협회</span></td>
                  <td><span class="td-text">2022-02-28</span></td>
                  <td><span class="td-text">2004-10-05</span></td>
                  <td><span class="td-text">-</span></td>
                  <td><span class="td-text">-</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- [end::popup-container] -->
</template>

<script>
export default {
  name: 'HistoryMandatoryCertificateModal',
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}){
    const closeModal = () => {
      emit('update:modelValue', false);
    }

    return {
      closeModal
    }
  }
};
</script>