<template>
  <main class="kb-main" id="kb-edutrain-dashboard">
    <lxp-main-header :show-title="true" :show-breadcrumb="true">
      <template v-slot:inner>
        <div class="header-column">
          <button class="page-help mt-0" @click="mandatoryCertificateModal = true">
            <span class="text">상품판매 필수 자격증</span>
            <i class="icon-help"></i>
          </button>
          <a href="javascript:" class="text-link"><span class="text">엑셀 다운로드</span><i class="icon-download"></i></a>
        </div>
      </template>
      <template v-slot:action>
        <HistoryYearSelector v-model="yearStr" :show-select-all="true" :yearCnt="6"/>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section -->
      <section class="content-section section-status">
        <div class="roundedbox-wrap roundedbox-low">
          <!-- roundedbox -->
          <div class="roundedbox" v-for="(item,idx) in summary" :key="idx">
            <div class="roundedbox-top">
              <div class="top-row">
                <h5 class="roundedbox-title">{{ item.crqfcToNm }}</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <span class="roundedbox-text">{{ checkFormat(item.val, item.valTp) }}</span>
            </div>
          </div>
        </div>
      </section>
      <!-- content-section:취득 자격증 등급 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">취득 자격증 등급</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
          <ToastColumnChart :categories="categories" :series="series" :options="options" />
        </div>
        <!-- //chart-wrap -->
      </section>
      <!-- content-section:취득 자격증 주관처 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">취득 자격증 주관처</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
          <ToastPieChart />
          <!-- chart-details -->
          <div class="chart-details">
            <div class="chart-rank">
              <div class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col style="width:45px;">
                    <col style="width:auto;">
                    <col style="width:auto;">
                  </colgroup>
                  <tbody>
                  <tr>
                    <th><strong class="th-title text-primary rank">1</strong></th>
                    <td><strong class="td-text">가계여신</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">49%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">2</strong></th>
                    <td><strong class="td-text">외환</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">27%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">3</strong></th>
                    <td><strong class="td-text">펀드</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">22%</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">4</strong></th>
                    <td><strong class="td-text">CRM</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">15%</strong></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
        <div class="learning-status-wrap">
          <div class="list-top">
            <div class="top-column align-self-end">
              <p class="title text-muted">총 {{certificates.length}}건</p>
            </div>
            <div class="top-column">
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" placeholder="검색"
                         v-model="params.lcsnNm"
                         @keyup.enter="clickSearch"
                  />
                  <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="kb-table kb-table-bordered kb-table-row learning-tablelist">
            <table>
              <colgroup>
                <col style="width:auto"/>
                <col style="width:180px"/>
                <col style="width:140px"/>
                <col style="width:140px"/>
                <col style="width:100px"/>
                <col style="width:130px"/>
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">자격증명</span></th>
                <th><span class="th-title">주관처</span></th>
                <th><span class="th-title">자격증코드</span></th>
                <th><span class="th-title">자격적용일</span></th>
                <th><span class="th-title">등급</span></th>
                <th><span class="th-title">지원 금액</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in certificates" :key="idx">
                <td><div class="text-truncate"><strong class="text">{{ item.lcsnNm }}</strong></div></td>
                <td><span class="text">{{ item.issuInstNm }}</span></td>
                <td><span class="text">{{ item.lcsnCd }}</span></td>
                <td><span class="text">{{ item.evlKindNm }}</span></td>
                <td><span class="text">{{ item.lcsnGrd }}</span></td>
                <td><span class="text">{{ (item.aprvAmt > 0 ? numberComma(item.aprvAmt) : '-') }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </section>
    </div>
    <!-- //main-content -->
    <HistoryMandatoryCertificateModal v-model="mandatoryCertificateModal"/>
  </main>
</template>
<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import HistoryMandatoryCertificateModal from '@/components/history/HistoryMandatoryCertificateModal';
import {ACT_GET_CERTIFICATE_LIST, ACT_GET_CERTIFICATE_SUMMARY_LIST} from '@/store/modules/history/history';
import {reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {checkFormat, getCheckItems, getPaging, initPaging, numberComma, summarizeAll} from '@/assets/js/util';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import ToastPieChart from '@/components/chart/ToastPieChart';
import ToastColumnChart from '@/components/chart/ToastColumnChart';

export default {
  name: 'HistoryCertificate',
  components: {
    ToastColumnChart,
    ToastPieChart,
    CommonPaginationFront, HistoryMandatoryCertificateModal, HistoryYearSelector, LxpMainHeader},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const params = reactive({
      lcsnNm: '',
    });

    const summary = ref([]);
    const certificates = ref([]);

    const mandatoryCertificateModal = ref(false);
    const yearStr = ref(new Date().getFullYear());

    const getSummary = async () => {
      await store.dispatch(`history/${ACT_GET_CERTIFICATE_SUMMARY_LIST}`, {
        yearStr: yearStr.value,
      }).then(res => {
        summary.value = getCheckItems(res);
        paging.value = getPaging(res);

        if (yearStr.value.toString().length === 0) {
          summary.value = summarizeAll(summary.value, 'crqfcToIdStr');
        }
      }).catch(e => {
        console.error(e);
      });
    };

    const getCertificateList = async () => {
      await store.dispatch(`history/${ACT_GET_CERTIFICATE_LIST}`, {
        ...params,
        yearStr: yearStr.value,
        ...paging.value,
      }).then(res => {
        certificates.value = getCheckItems(res);
        paging.value = getPaging(res);
      }).catch(e => {
        console.error(e);
      });
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo, year: yearStr.value};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (params.lcsnNm) query.lcsnNm = params.lcsnNm;
      if (yearStr.value) query.year = yearStr.value;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const categories = ref([
      'A등급', 'B등급', 'C등급', 'D등급', 'E등급', 'F등급',
    ]);
    const series = ref([
      {
        name: '등급',
        data: [92.7, 23.5, 15.6, 79.8, 33.6, 57.3]
      }
    ]);

    const options = ref({
      chart: { width: 1191, height: 298 },
      xAxis: {
        pointOnColumn: false,
        // title: { text: 'Month' }
      },
      // yAxis: { title: 'Temperature (Celsius)' },
      theme: {
        series: {
          areaOpacity: 0.7,
          colors: ['#ffcc00', '#e8e8e8'],
          barWidth: 30
        }
      },
      exportMenu: {
        visible: false
      },
      legend: {
        visible: false
      },
      usageStatistics: false
    });

    watch(() => yearStr.value, () => {
      paging.value = initPaging(route);
      getSummary();
      getCertificateList();
    });

    watch(() => route.query, () => {
      if (route.name === 'HistoryCertificate') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }

        getCertificateList();
      }
    });

    getSummary();
    getCertificateList();

    return {
      mandatoryCertificateModal,
      yearStr,
      params,
      summary,
      certificates,
      paging,
      pagingFunc,
      clickSearch,
      checkFormat,
      numberComma,
      categories,
      series,
      options
    };
  },
};
</script>